export default function Contact() {
    return <div>
        <h1>Contact</h1>
        <p>Thank you for visiting my website! I am always looking for new opportunities, ideas, and connections. Please feel free to contact me at:</p>  
        <p>Phone: +1 845-776-1341</p>
        <p>Email: alagaresdetoledo@gmail.com</p>
        <p>Ad Astra!</p>
    </div>
    
  }
  